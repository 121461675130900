import { useParams } from "react-router-dom";

import { useGetProjectById } from "@ll-web/features/projects/async/useProjectsQueries";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";

export const useActiveProject = (): {
  activeProject: ProjectWithDeliverables;
} => {
  const { id } = useParams();
  const query = useGetProjectById({ id });

  if (!query.isSuccess) {
    throw new Error(
      "Active Project is pending, missing ActiveProjectContainer",
      {
        cause: query.error,
      },
    );
  }

  return { activeProject: query.data };
};
