import { creatorAuthenticationService } from "@ll-web/creator/auth/async/CreatorAuthenticationService";
import { useQueryActiveCreator } from "@ll-web/creator/auth/async/hooks/hooks";
import type { Creator } from "@ll-web/creator/auth/types";

type UseCreatorReturn =
  | {
      isLoading: boolean;
      isAuthorized: true;
      activeCreator: Creator;
    }
  | {
      isLoading: boolean;
      isAuthorized: false;
      activeCreator: undefined;
    };

export function useCreator({
  enabled = true,
}: {
  enabled?: boolean;
} = {}): UseCreatorReturn {
  const tokens = creatorAuthenticationService.getUserTokens();

  const activeCreatorQuery = useQueryActiveCreator(
    {},
    {
      enabled: enabled && !!tokens,
    },
  );
  const activeCreator = activeCreatorQuery.data;

  if (activeCreator) {
    return {
      isLoading: false,
      isAuthorized: true,
      activeCreator,
    };
  } else {
    return {
      isLoading: activeCreatorQuery.isLoading,
      isAuthorized: false,
      activeCreator: undefined,
    };
  }
}
