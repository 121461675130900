import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { useActiveBrand } from "@ll-web/features/brands/hooks/useActiveBrand";
import { projectsService } from "@ll-web/features/projects/async/ProjectsService";
import { createQueryHook } from "@ll-web/utils/factories/createQueryHook";
import { createQueryOption } from "@ll-web/utils/factories/createQueryOption";

export const ProjectsQueries = createQueryKeys("projects", {
  findAll: createQueryOption(projectsService.findAll.bind(projectsService)),
  getById: createQueryOption(projectsService.getById.bind(projectsService)),
  getHeroVideosByProjectId: createQueryOption(
    projectsService.getHeroVideosByProjectId.bind(projectsService),
  ),
  getHeroVideoById: createQueryOption(
    projectsService.getHeroVideoById.bind(projectsService),
  ),
  getProductionDaysByProjectId: createQueryOption(
    projectsService.getProductionDaysByProjectId.bind(projectsService),
  ),
  getDroneProductionDaysByProjectId: createQueryOption(
    projectsService.getDroneProductionDaysByProjectId.bind(projectsService),
  ),
  getCharactersByProjectId: createQueryOption(
    projectsService.getCharactersByProjectId.bind(projectsService),
  ),
});

export function useGetProjectById({ id }: { id: string | undefined }) {
  return useQuery({
    ...ProjectsQueries.getById({ id: id! }),
    enabled: !!id,
    staleTime: 1000 * 60 * 5,
  });
}

export function useActiveBrandProjects() {
  const activeBrandData = useActiveBrand();

  return useQuery({
    ...ProjectsQueries.findAll({ brandId: activeBrandData?.activeBrand?._id }),
    enabled: !!activeBrandData?.activeBrand,
  });
}

export const useGetProductionDaysQuery = createQueryHook(
  ProjectsQueries.getProductionDaysByProjectId,
  {
    placeholderData: keepPreviousData,
  },
);

export const useGetDroneProductionsQuery = createQueryHook(
  ProjectsQueries.getDroneProductionDaysByProjectId,
  {
    placeholderData: keepPreviousData,
  },
);

export const useGetHeroVideoById = createQueryHook(
  ProjectsQueries.getHeroVideoById,
  {
    placeholderData: keepPreviousData,
  },
);

export const useGetCharactersByProjectId = createQueryHook(
  ProjectsQueries.getCharactersByProjectId,
);
