import { useEffect, useState } from "react";

import {
  identify,
  Identify,
  init,
  setUserId,
  track,
  Types,
} from "@amplitude/analytics-browser";
import type { ValidPropertyType } from "@amplitude/analytics-types";

import { APP_CONFIG } from "@ll-web/config/app.config";
import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import {
  buildEventName,
  isEventOnChannel,
} from "@ll-web/core/analytics/eventUtils";
import { useUser } from "@ll-web/features/auth/hooks/useUser";

import { EventChannel, type TrackingEvent } from "./types";

const amplitudeSegmentSeparator = " ";

type AmplitudeUserPropertyPayload = {
  property: string;
  value: ValidPropertyType;
};

export const updateAmplitudeUserProperty = (
  payload: AmplitudeUserPropertyPayload | AmplitudeUserPropertyPayload[],
) => {
  const identifyEvent = new Identify();
  Array.isArray(payload)
    ? payload.forEach(({ property, value }) =>
        identifyEvent.set(property, value),
      )
    : identifyEvent.set(payload.property, payload.value);
  identify(identifyEvent);
};

export const useAmplitude = () => {
  const [client] = useState(() => {
    const key = APP_CONFIG.REACT_APP_AMPLITUDE_KEY;
    if (!key) {
      return;
    }

    const instance = init(key, {
      logLevel: Types.LogLevel.Warn,
    });

    return instance;
  });

  const { activeUser } = useUser({
    enabled: false,
  });

  useEffect(() => {
    if (!client) {
      return;
    }
    if (activeUser) {
      identify(new Identify());
      setUserId(activeUser._id);
      updateAmplitudeUserProperty([
        { property: "email", value: activeUser.email },
        { property: "accountType", value: activeUser.accountType },
        { property: "isActive", value: activeUser.isActive },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(client), activeUser]);

  useEffect(() => {
    if (!client) {
      return;
    }

    return activityTracker.subscribe((event: TrackingEvent) => {
      // amplitude processes events on analytics channel
      if (!isEventOnChannel(event, EventChannel.Analytics)) {
        return;
      }
      track(
        buildEventName(event.type, amplitudeSegmentSeparator),
        event.metadata,
      );
    });
  }, [client]);
};
