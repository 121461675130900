import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { useActiveBrand } from "@ll-web/features/brands/hooks/useActiveBrand";
import { createQueryHook } from "@ll-web/utils/factories/createQueryHook";
import { createQueryOption } from "@ll-web/utils/factories/createQueryOption";

import { brandsService } from "./BrandsService";

export const BrandsQueries = createQueryKeys("brands", {
  getBrandByProjectId: createQueryOption(
    brandsService.getBrandByProjectId.bind(brandsService),
  ),
  getBrand: createQueryOption(brandsService.getBrand.bind(brandsService)),
  findAll: createQueryOption(brandsService.findAll.bind(brandsService)),
  findBrandByUserId: createQueryOption(
    brandsService.findAll.bind(brandsService),
  ),
  getBrandInvitations: createQueryOption(
    brandsService.getBrandInvitations.bind(brandsService),
  ),
  getBrandInviteByToken: createQueryOption(
    brandsService.getBrandInviteByToken.bind(brandsService),
  ),
  getBrandUsers: createQueryOption(
    brandsService.getBrandUsers.bind(brandsService),
  ),
});

export const useFindAllBrands = createQueryHook(BrandsQueries.findAll);

export const useFindBrandByUserId = createQueryHook(
  BrandsQueries.findBrandByUserId,
);

export const useGetBrandByProjectId = createQueryHook(
  BrandsQueries.getBrandByProjectId,
);

export const useGetActiveBrandInvitations = () => {
  const activeBrandData = useActiveBrand();

  return useQuery({
    ...BrandsQueries.getBrandInvitations({
      brandId: activeBrandData?.activeBrand?._id ?? "",
    }),
    enabled: !!activeBrandData?.activeBrand?._id,
  });
};

export const useBrandInviteByToken = createQueryHook(
  BrandsQueries.getBrandInviteByToken,
  { enabled: true, retry: 1, refetchOnWindowFocus: false },
);

export const useBrandUsers = createQueryHook(BrandsQueries.getBrandUsers);

export const useGetBrandById = createQueryHook(BrandsQueries.getBrand);
