import dayjs, { type Dayjs } from "dayjs";
import { isFunction, isNumber, isObject, isString } from "lodash-es";

import { DEFAULT_TIMEZONE } from "@ll-web/consts/globals";

export const mapUnknownToDate = (value: unknown): Date | null => {
  if (value instanceof Date) {
    return value;
  }

  if (isNumber(value) || isString(value)) {
    const date = new Date(value);

    if (!Number.isNaN(date.getTime())) {
      return date;
    }
  }

  if (!isObject(value)) {
    return null;
  }

  if ("toDate" in value && isFunction(value.toDate)) {
    return value.toDate() as Date;
  }

  if (
    "seconds" in value &&
    "nanoseconds" in value &&
    isNumber(value.seconds) &&
    isNumber(value.nanoseconds)
  ) {
    return new Date(value.seconds * 1000 + value.nanoseconds / 1000000);
  }

  if (
    "_seconds" in value &&
    "_nanoseconds" in value &&
    isNumber(value._seconds) &&
    isNumber(value._nanoseconds)
  ) {
    return new Date(value._seconds * 1000 + value._nanoseconds / 1000000);
  }

  if (isObject(value) && "toDate" in value && isFunction(value.toDate)) {
    if (value.toDate()) {
      return value.toDate() as Date;
    }
  }

  return null;
};

export const mapUnknownToDayjs = (value: unknown): Dayjs | null => {
  if (dayjs.isDayjs(value)) {
    return value;
  }

  const date = mapUnknownToDate(value);

  return date ? dayjs(date) : null;
};

export function mergeProductionDayDate(
  filmDate: Dayjs,
  startTime: Dayjs,
  timezone?: string,
) {
  const utcStartTime = startTime.utc();

  return filmDate
    .utc()
    .set("hour", utcStartTime.get("hour"))
    .set("minute", utcStartTime.get("minute"))
    .set("second", utcStartTime.get("second"))
    .set("millisecond", utcStartTime.get("millisecond"))
    .tz(timezone ?? DEFAULT_TIMEZONE);
}
