import { APP_CONFIG } from "@ll-web/config/app.config";

import type { ActivityType } from "./events";
import {
  EventChannel,
  type ActivityTypesWithoutMetadata,
  type TrackingEvent,
  type TrackingEventInput,
  type TypedTrackingEvent,
} from "./types";

type Handler = (event: TrackingEvent) => void;

let handlers: Handler[] = [];

if (APP_CONFIG.REACT_APP_ANALYTICS_DEBUG) {
  handlers.push((event) =>
    console.debug(
      `[activityTracker] #${event.channel} ${event.type}`,
      event.metadata,
    ),
  );
}

export const activityTracker = {
  log(input: ActivityTypesWithoutMetadata | TypedTrackingEvent) {
    if (typeof input === "string") {
      return fromString(input);
    }

    return fromEvent(input);
  },

  subscribe(handler: Handler) {
    handlers.push(handler);

    const unsubscribe = () => {
      handlers = handlers.filter((listHandler) => listHandler !== handler);
    };

    return unsubscribe;
  },

  clearHandlers() {
    handlers = [];
  },
};

const fromString = (type: ActivityType) => {
  fromEvent({ type });
};

const fromEvent = (eventInput: TrackingEventInput) => {
  const event = buildEvent(eventInput);

  for (const handler of handlers) {
    handler(event);
  }
};

const buildEvent = (eventInput: TrackingEventInput): TrackingEvent => {
  return {
    type: eventInput.type,
    channel: eventInput.channel || EventChannel.All,
    metadata: eventInput.metadata || {},
  };
};
