import { useEffect } from "react";

import Smartlook from "smartlook-client";

import { APP_CONFIG } from "@ll-web/config/app.config";
import { useUser } from "@ll-web/features/auth/hooks/useUser";

export const useSmartlook = () => {
  const { activeUser } = useUser({
    enabled: false,
  });

  useEffect(() => {
    const key = APP_CONFIG.REACT_APP_SMARTLOOK_KEY;
    if (!key) {
      return;
    }
    Smartlook.init(key);
  }, []);

  useEffect(() => {
    if (!activeUser) {
      return;
    }

    if (!Smartlook.initialized()) {
      return;
    }

    const { _id, email, accountType, isActive } = activeUser;
    Smartlook.identify(_id, { email, accountType, isActive });
  }, [activeUser]);
};
