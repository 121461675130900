import { Pages } from "@ll-web/core/router/pages";
import type { WizardSteps } from "@ll-web/features/projectWizard/consts/wizardSteps";
import { WizardAction } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";

export function makeWizardPath(
  projectId: string,
  action: WizardAction = WizardAction.Generate,
  step?: WizardSteps,
): string {
  return Pages.ProjectWizard.replace(":id", projectId)
    .replace(":action", action)
    .replace(":step", step ?? "");
}
