import type { ReactNode } from "react";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { useLogOut } from "@ll-web/features/auth/hooks/useLogOut";
import { useUser } from "@ll-web/features/auth/hooks/useUser";
import {
  ExternalAccountTypes,
  InternalAccountTypes,
  type AccountType,
} from "@ll-web/features/auth/types";
import { makeNextParamForCurrent } from "@ll-web/utils/helpers/navigation";

interface IsLoggedInGuardProps {
  accountTypes?: AccountType[];
  emailHint?: string;
  children?: ReactNode;
}

export const IsLoggedInGuard = ({
  children,
  emailHint,
  accountTypes = [],
}: IsLoggedInGuardProps) => {
  const { isLoading, isAuthorized, activeUser } = useUser();
  const logOut = useLogOut({ activeUser });

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (
    !isAuthorized ||
    (accountTypes.length > 0 && !accountTypes.includes(activeUser.accountType))
  ) {
    logOut({
      redirect: `/login?${makeNextParamForCurrent()}${
        emailHint ? `&${new URLSearchParams({ email: emailHint })}` : ""
      }`,
    });

    return null;
  }

  return children;
};

export const IsInternalLoggedInGuard = (
  props: Omit<IsLoggedInGuardProps, "accountTypes">,
) => {
  return <IsLoggedInGuard {...props} accountTypes={InternalAccountTypes} />;
};

export const IsExternalLoggedInGuard = (
  props: Omit<IsLoggedInGuardProps, "accountTypes">,
) => {
  return <IsLoggedInGuard {...props} accountTypes={ExternalAccountTypes} />;
};
