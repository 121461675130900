import { assertDefined } from "@ll-web/utils/types/types";

export function extractErrorMessage({
  error,
  fallbackMessage = "Unknown error",
}: {
  error: unknown;
  fallbackMessage?: string;
}): string {
  try {
    if (error instanceof Error && typeof error.message === "string") {
      return error.message;
    } else if (typeof error === "string") {
      return error;
    } else if (typeof error === "object") {
      assertDefined(error, "error");

      if (Array.isArray(error) && error[0]) {
        return error[0];
      }

      if ("message" in error) {
        if (typeof error.message === "string") {
          return error.message;
        } else if (typeof error.message === "object") {
          return extractErrorMessage({ error: error.message, fallbackMessage });
        }
      } else if ("data" in error) {
        return extractErrorMessage({ error: error.data, fallbackMessage });
      }
    }

    return fallbackMessage;
  } catch (e) {
    console.warn("Failed to extract error message", e);

    return fallbackMessage;
  }
}
