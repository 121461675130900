import type { ReactNode } from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";

import { lemonlightMuiLightTheme } from "@ll-web/core/theme/themes/LemonlightMui/LemonlightMuiLightTheme";

type GlobalThemeProviderProps = {
  children: ReactNode;
};

export const GlobalThemeProvider = ({ children }: GlobalThemeProviderProps) => {
  return (
    <ThemeProvider theme={lemonlightMuiLightTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
