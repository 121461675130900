import dayjs from "dayjs";

import { mapUnknownToDayjs } from "@ll-web/utils/helpers/date";

import type { DroneInput } from "./droneProductionDto";
import type { DroneProductionFormValues } from "./droneProductionSchema";

export const mapDroneProductionFormToDto = ({
  id,
  droneOperator,
  productionLength,
  tentativeCity,
  tentativeDate,
  startTime,
}: DroneProductionFormValues): DroneInput => {
  return {
    id: id!,
    date: tentativeDate ? dayjs(tentativeDate).toDate() : null,
    startTime: startTime ? dayjs(startTime).toDate() : null,
    droneOperator,
    duration: productionLength,
    location: tentativeCity ?? undefined,
  };
};

export const mapDroneProductionDtoToForm = ({
  id,
  date,
  droneOperator,
  duration,
  location,
}: DroneInput): DroneProductionFormValues => ({
  id,
  droneOperator: droneOperator!,
  productionLength: duration,
  tentativeCity:
    typeof location === "string" ? { description: location } : location ?? null,
  tentativeDate: mapUnknownToDayjs(date),
  startTime: mapUnknownToDayjs(date),
  isInEditMode: false,
});
