import dayjs from "dayjs";
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  where,
  writeBatch,
  type Timestamp,
} from "firebase/firestore";

import { httpClient } from "@ll-web/core/api/HttpClient";
import type { PaginatedResponse } from "@ll-web/core/api/pagination/pagination.types";
import {
  createRandomId,
  firestore,
} from "@ll-web/core/firebase/firebaseService";
import { FirestoreCollections } from "@ll-web/core/firebase/types";
import { brandsService } from "@ll-web/features/brands/async/BrandsService";
import { mapTalentsFormValuesToCharacters } from "@ll-web/features/internalProjects/projectForm/talents/mapTalentsInfoFormToDto";
import type {
  BulkUpdateMsaArgs,
  ByMsaParams,
  ByProjectIdParams,
  CreateNewProjectDto,
  ProjectFormInput,
  ProjectKickoffPayloadDto,
  ProjectNotifyCPPayloadDto,
  ProjectsArgs,
  UpdateProjectArgs,
} from "@ll-web/features/internalProjects/types";
import { charactersCollectionDiff } from "@ll-web/features/internalProjects/utils/charactersCollectionDiff";
import { projectsService } from "@ll-web/features/projects/async/ProjectsService";
import { ProjectSubCollections } from "@ll-web/features/projects/enums";
import type {
  ProjectCharacter,
  ProjectData,
  ProjectDataWithId,
  ProjectDrone,
  ProjectHeroVideo,
  ProjectProductionDay,
  ProjectWithDeliverables,
  ProjectWithDeliverablesAndBrand,
} from "@ll-web/features/projects/types";
import { ProjectAiOutputSubcollections } from "@ll-web/features/projectWizard/types";
import { collectionDiff } from "@ll-web/utils/helpers/collectionDiff";
import { type ByIdParams } from "@ll-web/utils/types/types";

const INCLUDED_SUBCOLLECTIONS = [
  ProjectSubCollections.HeroVideos,
  ProjectSubCollections.ProductionDays,
  ProjectSubCollections.DroneProductionDays,
] as const;

// Project related operations accessible only by Internal users
class InternalProjectsService {
  async createProject(projectInput: CreateNewProjectDto) {
    const {
      msa,
      title,
      style,
      productions,
      heroes,
      drones,
      timeline,
      notes,
      videoReferences,
      isMarketingCampaign,
      deliverables,
      rawFootageType,
      footageType,
      characters,
      wardrobeType,
      propsType,
    } = projectInput;

    const project: ProjectData = {
      msa,
      title,
      style,
      timeline,
      notes,
      videoReferences,
      isMarketingCampaign,
      deliverables,
      rawFootageType,
      footageType,
      ...(wardrobeType ? { wardrobeType } : {}),
      ...(propsType ? { propsType } : {}),
    };

    const charactersDto = characters
      ? mapTalentsFormValuesToCharacters(characters).map((character) => ({
          ...character,
        }))
      : undefined;

    const projectId = await this.createProjectDocument(project);

    await this.saveProjectSubcollections(projectId, {
      productions,
      heroes,
      drones,
      characters: charactersDto,
    });

    return projectId;
  }

  // FIXME: This function should use firestore batching, but it's good enough for now
  async updateProject({ projectId, ...projectInput }: UpdateProjectArgs) {
    const {
      msa,
      title,
      style,
      productions,
      heroes,
      drones,
      timeline,
      notes,
      setNotes,
      videoReferences,
      isMarketingCampaign,
      deliverables,
      rawFootageType,
      footageType,
      characters,
      wardrobeType,
      propsType,
    } = projectInput;

    const project: ProjectData = {
      msa,
      title,
      style,
      timeline,
      notes,
      setNotes,
      videoReferences,
      isMarketingCampaign,
      deliverables,
      rawFootageType,
      footageType,
      ...(wardrobeType ? { wardrobeType } : {}),
      ...(propsType ? { propsType } : {}),
    };

    const existingProductionsPromise =
      projectsService.getSubCollection<ProjectProductionDay>(
        projectId,
        ProjectSubCollections.ProductionDays,
      );
    const existingHeroesPromise =
      projectsService.getSubCollection<ProjectHeroVideo>(
        projectId,
        ProjectSubCollections.HeroVideos,
      );
    const existingDronesPromise =
      projectsService.getSubCollection<ProjectDrone>(
        projectId,
        ProjectSubCollections.DroneProductionDays,
      );
    const existingCharactersPromise =
      projectsService.getSubCollection<ProjectCharacter>(
        projectId,
        ProjectSubCollections.Characters,
      );
    const existingProductions = await existingProductionsPromise;
    const existingHeroes = await existingHeroesPromise;
    const existingDrones = await existingDronesPromise;
    const existingCharacters = await existingCharactersPromise;

    const productionsDiff = collectionDiff(existingProductions, productions);
    const heroesDiff = collectionDiff(existingHeroes, heroes);
    const dronesDiff = collectionDiff(existingDrones, drones);
    const charactersDiff = characters
      ? charactersCollectionDiff(existingCharacters, characters)
      : null;

    const shouldResetProjectStatus =
      !!productionsDiff.added.length ||
      !!productionsDiff.removed.length ||
      !!heroesDiff.added.length ||
      !!heroesDiff.removed.length;

    const clearFieldSentinel = deleteField() as unknown as undefined;
    // Some changes to project form, break the project wizard - WEB-2475
    if (shouldResetProjectStatus) {
      project.externalStatus = clearFieldSentinel;
      project.status = clearFieldSentinel;
      project.isApprovedByClient = clearFieldSentinel;
      project.clientFirstFinishedPreproductionAt = clearFieldSentinel;
      project.successfullyFirstFinalizedPreproductionAt = clearFieldSentinel;
    }

    const updatesPromises = [
      // Project
      projectsService.updateProjectDocument({ id: projectId, data: project }),

      // Productions
      ...productionsDiff.added.map((production) =>
        this.createProductionDay(projectId, production),
      ),
      ...productionsDiff.removed.map((production) =>
        this.removeDoc(
          projectId,
          ProjectSubCollections.ProductionDays,
          production.id,
        ),
      ),
      ...productionsDiff.rest.map(({ id, ...data }) =>
        projectsService.updateProductionDay({
          projectId,
          id,
          data,
        }),
      ),

      // Heroes
      ...heroesDiff.added.map((heroVideo) =>
        this.createHeroVideo(projectId, heroVideo),
      ),
      ...heroesDiff.removed.map((heroVideo) =>
        this.removeDoc(
          projectId,
          ProjectSubCollections.HeroVideos,
          heroVideo.id,
        ),
      ),
      ...heroesDiff.rest.map(({ id, ...data }) =>
        projectsService.updateHeroVideo({
          projectId,
          id,
          data,
        }),
      ),

      // Drones
      ...dronesDiff.added.map((droneVideo) =>
        this.createDroneProductionDay(projectId, droneVideo),
      ),
      ...dronesDiff.removed.map((droneVideo) =>
        this.removeDoc(
          projectId,
          ProjectSubCollections.DroneProductionDays,
          droneVideo.id,
        ),
      ),
      ...dronesDiff.rest.map(({ id, ...data }) =>
        projectsService.updateDroneProductionDay(projectId, id, data),
      ),
    ].concat(
      charactersDiff
        ? [
            ...charactersDiff.added.map((character) =>
              this.createCharacter(projectId, character),
            ),
            ...charactersDiff.removed.map((character) =>
              this.removeDoc(
                projectId,
                ProjectSubCollections.Characters,
                character.id,
              ),
            ),
          ]
        : [],
    );

    await Promise.all(updatesPromises);
  }

  async saveProjectSubcollections(
    projectId: string,
    {
      productions = [],
      heroes = [],
      drones = [],
      characters,
    }: Pick<ProjectFormInput, `${(typeof INCLUDED_SUBCOLLECTIONS)[number]}`> & {
      characters?: ProjectCharacter[];
    },
  ) {
    const productionPromises = productions.map(async (production) => {
      const projectProduction: ProjectProductionDay = {
        ...production,
        filmDate: production.filmDate,
      };

      return this.createProductionDay(projectId, projectProduction);
    });

    const heroPromises = heroes.map(async (hero) => {
      const projectHeroVideo: ProjectHeroVideo = {
        ...hero,
      };

      return this.createHeroVideo(projectId, projectHeroVideo);
    });

    const dronePromises = drones.map(async (drone) => {
      const projectDroneVideo: ProjectDrone = {
        ...drone,
      };

      return this.createDroneProductionDay(projectId, projectDroneVideo);
    });

    const talentsInfoPromise = characters
      ? characters.map(async (character) => {
          return this.createCharacter(projectId, { ...character });
        })
      : Promise.resolve();

    await Promise.all([
      ...productionPromises,
      ...heroPromises,
      ...dronePromises,
      talentsInfoPromise,
    ]);
  }

  async createProjectDocument(data: ProjectData): Promise<string> {
    const collectionRef = collection(firestore, FirestoreCollections.Projects);
    const docRef = await addDoc(collectionRef, {
      ...data,
      ["lastUpdated" satisfies keyof ProjectData]: serverTimestamp(),
      ["createdAt" satisfies keyof ProjectData]: serverTimestamp(),
    });

    return docRef.id;
  }

  async list(
    args: ProjectsArgs,
  ): Promise<PaginatedResponse<ProjectWithDeliverablesAndBrand>> {
    const paginatedProjects = await httpClient.unwrappedHttpRequest<
      PaginatedResponse<ProjectWithDeliverablesAndBrand>
    >({
      config: {
        method: "GET",
        url: "/v1/projects",
        params: args,
      },
    });

    return {
      ...paginatedProjects,
      items: paginatedProjects.items.map((project) => ({
        ...project,
        heroes: project.heroes ?? [],
        productions: project.productions ?? [],
        drones: project.drones ?? [],
      })),
    };
  }

  async deleteProject(args: ByProjectIdParams): Promise<void> {
    await httpClient.unwrappedHttpRequest<void>({
      config: {
        method: "DELETE",
        url: `/v1/projects/${args.projectId}`,
      },
    });
  }

  async findByMsa(args: ByMsaParams): Promise<ProjectWithDeliverables[]> {
    const collectionRef = collection(firestore, FirestoreCollections.Projects);
    const result = await getDocs(
      query(
        collectionRef,
        where("msa" satisfies keyof ProjectData, "==", args.msa),
        orderBy("lastUpdated" satisfies keyof ProjectData, "desc"),
      ),
    );

    const includedSubcollections = [
      ProjectSubCollections.HeroVideos,
      ProjectSubCollections.ProductionDays,
      ProjectSubCollections.DroneProductionDays,
    ] as const;

    const projects = await Promise.all(
      result.docs.map(async (doc) => {
        const subcollectionsData = await Promise.all(
          includedSubcollections.map(async (subcollection) => [
            subcollection,
            await projectsService.getSubCollection(doc.id, subcollection),
          ]),
        );

        return {
          id: doc.id,
          ...(doc.data() as ProjectData),
          ...Object.fromEntries(subcollectionsData),
        } as ProjectWithDeliverables;
      }),
    );

    return projects;
  }

  async findIdsByMsa(args: ByMsaParams): Promise<string[]> {
    const collectionRef = collection(firestore, FirestoreCollections.Projects);
    const result = await getDocs(
      query(collectionRef, where("msa", "==", args.msa)),
    );

    const projectIds = result.docs.map((doc) => doc.id);

    return projectIds;
  }

  // WARN: Only use this method on pending/draft contracts which haven't been finalized
  // If the projects have already been assgined to the brand, it will cause data inconsistency!
  async bulkUpdateMsa(args: BulkUpdateMsaArgs): Promise<ProjectDataWithId[]> {
    if (args.fromMsa === args.toMsa) {
      throw new Error("From MSA and To MSA cannot be the same");
    }

    const collectionRef = collection(firestore, FirestoreCollections.Projects);
    const projects = await getDocs(
      query(collectionRef, where("msa", "==", args.fromMsa)),
    );

    const batch = writeBatch(firestore);

    projects.forEach((doc) => {
      const docRef = doc.ref;
      batch.update(docRef, { msa: args.toMsa } satisfies Partial<ProjectData>);
    });

    await batch.commit();

    return projects.docs.map((doc) => {
      return { id: doc.id, ...(doc.data() as ProjectData), msa: args.toMsa };
    });
  }

  async kickoff(args: ProjectKickoffPayloadDto) {
    await httpClient.unwrappedHttpRequest<void>({
      config: {
        method: "POST",
        url: "/v1/projects/kickoff",
        data: args,
      },
    });
  }

  async notifyCP(args: ProjectNotifyCPPayloadDto): Promise<void> {
    await httpClient.unwrappedHttpRequest<void>({
      config: {
        method: "POST",
        url: "/v1/projects/notify-creative-producer",
        data: args,
      },
    });
  }

  async createProductionDay(
    projectId: string,
    production: ProjectProductionDay,
  ) {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.ProductionDays,
    );
    const docRef = await addDoc(collectionRef, production);

    return docRef.id;
  }

  async createHeroVideo(projectId: string, heroVideo: ProjectHeroVideo) {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.HeroVideos,
    );
    const heroVideoWithCutDownIds = {
      ...heroVideo,
      cutDowns: heroVideo.cutDowns?.map((cutDown) => ({
        ...cutDown,
        id: cutDown.id ?? createRandomId(),
      })),
    };
    const docRef = await addDoc(collectionRef, heroVideoWithCutDownIds);

    return docRef.id;
  }

  async createDroneProductionDay(projectId: string, droneVideo: ProjectDrone) {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.DroneProductionDays,
    );
    const docRef = await addDoc(collectionRef, droneVideo);

    return docRef.id;
  }

  async createCharacter(
    projectId: string,
    character: Omit<ProjectCharacter, "id">,
  ) {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Characters,
    );
    const docRef = await addDoc(collectionRef, character);

    return docRef.id;
  }

  async clone({ id }: ByIdParams): Promise<ProjectDataWithId> {
    const projectDocRef = doc(firestore, FirestoreCollections.Projects, id);
    const project = await getDoc(projectDocRef);
    if (!project.exists()) {
      throw new Error(`Project ${id} not found`);
    }
    const projectData = { ...project.data() } as ProjectData;

    const collectionRef = collection(firestore, FirestoreCollections.Projects);

    projectData.notes += `\n\n### ${dayjs().toISOString()}\nCloned from project: ${id} "${
      projectData.title
    }"\n`;

    const clonePrefix = "@";
    const existingVersion = projectData.title.match(
      new RegExp(`${clonePrefix}(\\S+)$`),
    )?.[1];
    const cloneVersion = dayjs().format("MMDDYY:HHmmss");
    if (existingVersion) {
      projectData.title = projectData.title.replace(
        `${clonePrefix}${existingVersion}`,
        `${clonePrefix}${cloneVersion}`,
      );
    } else {
      projectData.title = `${projectData.title} ${clonePrefix}${cloneVersion}`;
    }

    delete projectData.status;
    delete projectData.externalStatus;
    delete projectData.isApprovedByClient;
    projectData.lastUpdated = serverTimestamp() as Timestamp;

    const clonedDocRef = await addDoc(
      collectionRef,
      this.attachClonedMetadata(id, projectData),
    );

    const projectBrand = await brandsService.getBrandByProjectId({
      projectId: id,
    });
    await brandsService.addProjectToBrand({
      brandId: projectBrand._id,
      projectId: clonedDocRef.id,
    });

    await this.cloneSubcollections(id, clonedDocRef.id);

    const clonedProjectData = await getDoc(clonedDocRef);

    return {
      id: clonedDocRef.id,
      ...(clonedProjectData.data() as ProjectData),
    };
  }

  protected async removeDoc(
    projectId: string,
    subcollection: ProjectSubCollections,
    docId: string,
  ) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      subcollection,
      docId,
    );
    await deleteDoc(docRef);
  }

  protected async cloneSubcollections(
    fromId: string,
    toId: string,
  ): Promise<void> {
    const subcollections = [
      ...Object.values(ProjectSubCollections),
      ...Object.values(ProjectAiOutputSubcollections),
    ];

    const batch = writeBatch(firestore);

    await Promise.all(
      subcollections.map(async (subcollection) => {
        const subcollectionRef = collection(
          firestore,
          FirestoreCollections.Projects,
          fromId,
          subcollection,
        );
        const clonedSubcollectionRef = collection(
          firestore,
          FirestoreCollections.Projects,
          toId,
          subcollection,
        );

        const subcollectionQuery = await getDocs(query(subcollectionRef));
        subcollectionQuery.docs.forEach((document) =>
          batch.set(
            doc(clonedSubcollectionRef, document.id),
            this.attachClonedMetadata(fromId, document.data()),
          ),
        );
      }),
    );

    await batch.commit();
  }

  protected attachClonedMetadata<T extends object>(fromId: string, data: T) {
    return {
      ...data,
      _clonedAt: new Date(),
      _clonedProjectId: fromId,
      _originalProjectId:
        "_originalProjectId" in data && data._originalProjectId
          ? data._originalProjectId
          : fromId,
    };
  }
}

export const internalProjectsService = new InternalProjectsService();
