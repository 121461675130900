import { authenticationService } from "@ll-web/features/auth/async/AuthenticationService";
import { useQueryActiveUser } from "@ll-web/features/auth/async/hooks";
import type { User } from "@ll-web/features/auth/types";

type UseUserReturn =
  | {
      isLoading: boolean;
      isAuthorized: true;
      activeUser: User;
    }
  | {
      isLoading: boolean;
      isAuthorized: false;
      activeUser: undefined;
    };

export function useUser({
  enabled = true,
}: {
  enabled?: boolean;
} = {}): UseUserReturn {
  const tokens = authenticationService.getUserTokens();

  const activeUserQuery = useQueryActiveUser(
    {},
    {
      enabled: enabled && !!tokens,
    },
  );
  const activeUser = activeUserQuery.data;

  if (activeUser) {
    return {
      isLoading: false,
      isAuthorized: true,
      activeUser,
    };
  } else {
    return {
      isLoading: activeUserQuery.isLoading,
      isAuthorized: false,
      activeUser: undefined,
    };
  }
}
