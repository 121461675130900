import { useQueryClient } from "@tanstack/react-query";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { authenticationService } from "@ll-web/features/auth/async/AuthenticationService";
import type { User } from "@ll-web/features/auth/types";

export function useLogOut({ activeUser }: { activeUser?: User } = {}) {
  const queryClient = useQueryClient();

  return ({ redirect = "/login" }: { redirect?: string | false } = {}) => {
    if (activeUser) {
      activityTracker.log(ActivityType.LogoutUser);
    }

    queryClient.clear();
    sessionStorage.clear();
    authenticationService.clearUserTokens();

    if (redirect) {
      // Load the page from scratch
      window.location.href = redirect;
    }
  };
}
