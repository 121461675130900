import { useMemo } from "react";

import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { useFindBrandByUserId } from "@ll-web/features/brands/async/useBrandsQueries";

export function useActiveBrand() {
  const { activeUser } = useActiveUser();

  const brandsQuery = useFindBrandByUserId(
    {
      limit: 1,
      userId: activeUser?._id,
    },
    { enabled: !!activeUser && !isUserInternal(activeUser) },
  );
  const activeBrand = useMemo(() => {
    return {
      activeBrand: brandsQuery.data?.[0],
      isFetched: brandsQuery.isFetched,
      isLoading: brandsQuery.isPending,
    };
  }, [brandsQuery.data, brandsQuery.isFetched, brandsQuery.isPending]);

  if (activeUser && isUserInternal(activeUser)) {
    return null;
  }

  return activeBrand;
}
