/* eslint-disable import/no-unused-modules */
import "@ll-web/config/setupSentry";
import "@ll-web/config/setupLoadErrorHandler";
import "@ll-web/config/setupYup";
import "@ll-web/config/setupDayjs";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { HelmetProvider } from "react-helmet-async";

import { setupNewRelic } from "@ll-web/config/setupNewRelic";

import App from "./App";

setupNewRelic();

const root = createRoot(document.getElementById("root")!);

root.render(
  <StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </StrictMode>,
);
