import { useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import {
  QueryParameters,
  type SourceType,
} from "@ll-web/consts/query-parameters";
import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { useUpdateActiveUserSettings } from "@ll-web/features/auth/userSettings/async/useUserSettingsMutations";
import { useGetActiveUserSettings } from "@ll-web/features/auth/userSettings/async/useUserSettingsQueries";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { defined } from "@ll-web/utils/types/types";

export function useWizardUserAnalytics() {
  const { activeUser } = useActiveUser();
  const { data: userSettings } = useGetActiveUserSettings();
  const { mutateAsync: updateUserSettings, isPending } =
    useUpdateActiveUserSettings();
  const [params] = useSearchParams();
  const source = (params.get(QueryParameters.Source) ??
    "dashboard") as SourceType;

  useEffect(() => {
    if (
      !isUserInternal(activeUser) &&
      !defined(userSettings.firstWizardViewAt) &&
      !isPending
    ) {
      activityTracker.log({
        type: ActivityType.WizardUserVisitedFirstTime,
        metadata: {
          source,
        },
      });
      // eslint-disable-next-line no-void
      void updateUserSettings({
        firstWizardViewAt: new Date(),
      });
    }
  }, [activeUser, isPending, source, updateUserSettings, userSettings]);
}
