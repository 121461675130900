import type { ReactNode } from "react";

import { Navigate, useSearchParams } from "react-router-dom";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { useActiveBrandUser } from "@ll-web/features/brands/hooks/useActiveBrandUser";
import {
  BrandRoleEnum,
  ExternalBrandRoleTypes,
  InternalBrandRoleTypes,
} from "@ll-web/features/brands/types";
import { getNextParam } from "@ll-web/utils/helpers/navigation";

interface IsBrandMemberLoggedInProps {
  children: ReactNode;
  brandRoles?: BrandRoleEnum[];
}

export const IsBrandMemberLoggedInGuard = ({
  children,
  brandRoles,
}: IsBrandMemberLoggedInProps) => {
  const activeBrandUserData = useActiveBrandUser();
  const [searchParams] = useSearchParams();
  const next = getNextParam(searchParams);

  if (
    activeBrandUserData?.isLoading ||
    (activeBrandUserData && !activeBrandUserData?.isFetched)
  ) {
    return <SuspenseLoader />;
  }

  if (
    !activeBrandUserData?.activeBrandUser ||
    (!!brandRoles &&
      !brandRoles?.includes(activeBrandUserData?.activeBrandUser?.role))
  ) {
    return <Navigate to={next ?? "/"} replace />;
  }

  return children;
};

// eslint-disable-next-line import/no-unused-modules
export const IsBrandInternalLoggedInGuard = (
  props: Omit<IsBrandMemberLoggedInProps, "brandRoles">,
) => {
  return (
    <IsBrandMemberLoggedInGuard
      {...props}
      brandRoles={InternalBrandRoleTypes}
    />
  );
};

// eslint-disable-next-line import/no-unused-modules
export const IsBrandExternalLoggedInGuard = (
  props: Omit<IsBrandMemberLoggedInProps, "brandRoles">,
) => {
  return (
    <IsBrandMemberLoggedInGuard
      {...props}
      brandRoles={ExternalBrandRoleTypes}
    />
  );
};

export const IsBrandOwnerLoggedInGuard = (
  props: Omit<IsBrandMemberLoggedInProps, "brandRoles">,
) => {
  return (
    <IsBrandMemberLoggedInGuard {...props} brandRoles={[BrandRoleEnum.Owner]} />
  );
};

// eslint-disable-next-line import/no-unused-modules
export const IsBrandEditorLoggedInGuard = (
  props: Omit<IsBrandMemberLoggedInProps, "brandRoles">,
) => {
  return (
    <IsBrandMemberLoggedInGuard
      {...props}
      brandRoles={[BrandRoleEnum.Editor]}
    />
  );
};
