import { useEffect } from "react";

import { useCreator } from "@ll-web/creator/auth/hooks/useCreator";
import { useUser } from "@ll-web/features/auth/hooks/useUser";
import { klaviyoService } from "@ll-web/integrations/klaviyo/async/KlaviyoService";

import { activityTracker } from "./activityTracker";
import { buildEventName, isEventOnChannel } from "./eventUtils";
import { EventChannel, type TrackingEvent } from "./types";

const EVENT_NAME_SEPARATOR = " " as const;

export const useKlaviyo = () => {
  const { activeUser } = useUser({
    enabled: false,
  });
  const { activeCreator } = useCreator({
    enabled: false,
  });

  useEffect(() => {
    if (!activeUser && !activeCreator) {
      return;
    }

    return activityTracker.subscribe((event: TrackingEvent) => {
      // klaviyo processes events on engagement channel
      if (!isEventOnChannel(event, EventChannel.EngagementTriggers)) {
        return;
      }
      klaviyoService.makeCreateEvent({
        eventName: buildEventName(event.type, EVENT_NAME_SEPARATOR),
        eventService: "frontend",
        metadata: event.metadata,
      });
    });
  }, [activeUser, activeCreator]);
};
