import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
  WithFieldValue,
} from "firebase/firestore";

import type {
  ProjectDrone,
  ProjectProductionDay,
} from "@ll-web/features/projects/types";
import { mapUnknownToDate } from "@ll-web/utils/helpers/date";

export const projectProductionDayConverter: FirestoreDataConverter<
  ProjectProductionDay,
  ProjectProductionDay
> = {
  toFirestore: (data: WithFieldValue<ProjectProductionDay>) => data,
  fromFirestore(snap: QueryDocumentSnapshot) {
    return {
      ...snap.data(),
      id: snap.id,
      startTime: (snap.data().startTime as Timestamp)?.toDate(),
      filmDate: (snap.data().filmDate as Timestamp)?.toDate(),
    } as ProjectProductionDay;
  },
};

export const droneProductionConverter: FirestoreDataConverter<
  ProjectDrone,
  ProjectDrone
> = {
  toFirestore: (data: WithFieldValue<ProjectDrone>) => data,
  fromFirestore(snap: QueryDocumentSnapshot) {
    return {
      ...snap.data(),
      id: snap.id,
      startTime: (snap.data().startTime as Timestamp)?.toDate(),
      date: (snap.data().filmDate as Timestamp)?.toDate(),
    } as ProjectDrone;
  },
};

export const dataWithIdConverterFactory = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore(snap: QueryDocumentSnapshot<T>) {
    const data = snap.data();

    return {
      ...snap.data(),
      id: snap.id,
      ...(typeof data === "object" && data !== null && "createdAt" in data
        ? { createdAt: mapUnknownToDate(data.createdAt as Timestamp) }
        : {}),
    } as T & { id: string };
  },
});
