import { makeMarkdownLink } from "@ll-web/features/textEditor/serializers/markdown";
import { replaceMarkdownLinks } from "@ll-web/features/textEditor/serializers/plainText";
import { TextEditorElementEnum } from "@ll-web/features/textEditor/types";
import { defined, typedEntries } from "@ll-web/utils/types/types";

export const stripHiddenOutputContent = <T>(output: T): T => {
  if (!defined(output)) {
    return output;
  }

  return removeMarkdownLinks(output) as T;
};

const removeMarkdownLinks = <T>(output: T): T => {
  if (typeof output === "string") {
    return replaceMarkdownLinks(output) as T;
  }

  if (Array.isArray(output)) {
    return output.map((item) => removeMarkdownLinks(item)) as T;
  }

  if (typeof output === "object" && output !== null) {
    return Object.fromEntries(
      typedEntries(output).map(([key, value]) => [
        key,
        removeMarkdownLinks(value),
      ]),
    );
  }

  return output;
};

export const getFirstCommentId = (text: string): string | null => {
  const commentRegex = /\[(.*?)\]\(comment.*?"id":"(.*?)".*?\)/g;
  const match = commentRegex.exec(text);

  if (match?.[2]) {
    return match[2];
  }

  return null;
};

export const reapplyAgendaTimeComment = (
  text: string,
  commentId: string | null,
): string => {
  if (!commentId) {
    return text;
  }

  return makeMarkdownLink({
    children: [{ text }],
    comment: {
      id: commentId,
    },
    type: TextEditorElementEnum.Comment,
  });
};
