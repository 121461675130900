import { useQueryClient } from "@tanstack/react-query";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { creatorAuthenticationService } from "@ll-web/creator/auth/async/CreatorAuthenticationService";
import type { Creator } from "@ll-web/creator/auth/types";
import { CreatorPages } from "@ll-web/creator/pages";

export function useCreatorLogOut({
  activeCreator,
}: {
  activeCreator?: Creator;
} = {}) {
  const queryClient = useQueryClient();

  return ({
    redirect = CreatorPages.Login,
  }: { redirect?: string | false } = {}) => {
    if (activeCreator) {
      activityTracker.log(ActivityType.LogoutUser);
    }

    queryClient.clear();
    sessionStorage.clear();
    creatorAuthenticationService.clearUserTokens();

    if (redirect) {
      // Load the page from scratch
      window.location.href = redirect;
    }
  };
}
