import type { ReactNode } from "react";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { useCreator } from "@ll-web/creator/auth/hooks/useCreator";
import { useCreatorLogOut } from "@ll-web/creator/auth/hooks/useLogOut";
import type { AccountType } from "@ll-web/creator/auth/types";
import { CreatorPages } from "@ll-web/creator/pages";
import { makeNextParamForCurrent } from "@ll-web/utils/helpers/navigation";

interface IsLoggedInGuardProps {
  children: ReactNode;
  accountTypes?: AccountType[];
  emailHint?: string;
}

export const IsLoggedInGuard = ({
  children,
  emailHint,
  accountTypes,
}: IsLoggedInGuardProps) => {
  const { isLoading, isAuthorized, activeCreator } = useCreator();
  const logOut = useCreatorLogOut({ activeCreator });

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (
    !isAuthorized ||
    (accountTypes && !accountTypes.includes(activeCreator.accountType))
  ) {
    logOut({
      redirect: `${CreatorPages.Login}?${makeNextParamForCurrent()}${
        emailHint ? `&${new URLSearchParams({ email: emailHint })}` : ""
      }`,
    });

    return null;
  }

  return children;
};
