import type { To } from "react-router-dom";

import { UserGroup } from "@ll-web/features/auth/types";

export enum Pages {
  AuthRedirect = "/",
  InternalBase = "/internal",
  InternalBrandRedirect = "/internal/brand/:id",
  InternalBrandPage = "/internal/brand/:id/:tab",
  InternalDashboardRedirect = "/internal/dashboard",
  InternalProjects = "/internal/dashboard/projects",
  InternalNewProject = "/internal/project/new",
  InternalEditProject = "/internal/project/edit/:id",
  InternalContracts = "/internal/dashboard/contracts",
  InternalNewContract = "/internal/dashboard/contracts/new",
  InternalEditContract = "/internal/dashboard/contracts/edit/:id",
  InternalProjectSpace = "/internal/project-space/:id",
  InternalPromptPlayground = "/internal/playgrounds/prompt",
  InternalWardrobePlayground = "/internal/playgrounds/wardrobe",
  ProjectSpacePlayground = "/internal/playgrounds/project-space",
  InternalThemePlayground = "/internal/playgrounds/theme",
  InternalTextEditorPlayground = "/internal/playgrounds/text-editor",
  DashboardRedirect = "/dashboard",
  ExternalProjects = "/dashboard/projects",
  ExternalProjectSpace = "/project-space/:id",
  ProjectWizard = "/dashboard/project/:id/:action/:step",
  Register = "/register",
  Login = "/login",
  BrandOnboarding = "/brand-onboarding",
  ResetPassword = "/reset-password",
  AcceptTeamInvite = "/accept-team-invite",
  AuthGoogleCallback = "/auth/google/callback",
  SettingsMyAccount = "/settings/my-account",
  SettingsTeam = "/settings/team",
  SettingsTeamGeneral = "/settings/team/general",
  SettingsTeamMembers = "/settings/team/members",
  Creator = "/creator",
  Status404 = "/status/404",
}

export const DashboardUserGroupMap: Record<UserGroup, To> = {
  [UserGroup.Internal]: Pages.InternalDashboardRedirect,
  [UserGroup.External]: Pages.DashboardRedirect,
  [UserGroup.Creator]: Pages.Creator,
};

export const ProjectSpaceUserGroupMap: Record<UserGroup, Pages> = {
  [UserGroup.Internal]: Pages.InternalProjectSpace,
  [UserGroup.External]: Pages.ExternalProjectSpace,
  [UserGroup.Creator]: Pages.Creator,
};

// eslint-disable-next-line import/no-unused-modules
export const DashboardProjectsUserGroupMap: Record<UserGroup, To> = {
  [UserGroup.Internal]: Pages.InternalProjects,
  [UserGroup.External]: Pages.ExternalProjects,
  [UserGroup.Creator]: Pages.Creator,
};
