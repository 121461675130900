import axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";

import { APP_CONFIG } from "@ll-web/config/app.config";
import { authenticationService } from "@ll-web/features/auth/async/AuthenticationService";
import type { UserTokens } from "@ll-web/features/auth/types";
import { makeNextParamForCurrent } from "@ll-web/utils/helpers/navigation";

import { DEFAULT_REQUEST_TIMEOUT } from "./consts";

const axiosInstance = axios.create({
  baseURL: APP_CONFIG.REACT_APP_API_URL,
  timeout: DEFAULT_REQUEST_TIMEOUT,
  headers: { Accept: "application/json" },
  transitional: {
    silentJSONParsing: false,
    forcedJSONParsing: true,
    clarifyTimeoutError: true,
  },
});

enum HttpMethods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

type HttpMethod = `${HttpMethods}`;

type RequestConfig = Omit<AxiosRequestConfig, "method"> & {
  method: HttpMethod;
};

// eslint-disable-next-line import/no-unused-modules
export type ApiResponse<T> = {
  status: string;
  data: T;
  code: number;
};

type HttpRequestArgs = {
  config: RequestConfig;
  withAuth?: boolean;
  handleUnauthorized?: () => void;
};

export class HttpClient {
  public async unwrappedHttpRequest<T>(args: HttpRequestArgs): Promise<T> {
    try {
      const response = await httpClient.httpRequest<ApiResponse<T>>(args);

      return response.data.data;
    } catch (e) {
      const error = e as AxiosError;
      throw error.response ?? error;
    }
  }

  public async httpRequest<T>({
    config,
    withAuth = true,
    handleUnauthorized = this.defaultHandleUnauthorized.bind(this),
  }: HttpRequestArgs): Promise<AxiosResponse<T>> {
    let authHeaders = {};
    if (withAuth) {
      try {
        authHeaders = await this.getAuthHeaders();
      } catch (e) {
        handleUnauthorized();
      }
    }
    const mergedConfig = {
      ...config,
      headers: {
        ...authHeaders,
        ...config.headers,
      },
    };

    const axiosResponse = await axiosInstance.request(mergedConfig);

    return this.processResponse(axiosResponse, { config, withAuth });
  }

  public async getAuthHeaders() {
    const tokens = this.getUserTokens();

    if (!tokens?.accessToken) {
      throw new Error("Unauthorized");
    }

    const headers = {
      Authorization: `Bearer ${tokens.accessToken}`,
    } satisfies RequestConfig["headers"];

    return headers;
  }

  public getFilenameFromResponse(response: AxiosResponse<Blob>) {
    const filename = response.headers["content-disposition"]
      ?.split("filename=")[1]
      ?.slice(1, -1);

    if (!filename) {
      throw new Error("Response is missing filename");
    }

    return filename;
  }

  private processResponse(
    axiosResponse: AxiosResponse,
    { withAuth, handleUnauthorized }: HttpRequestArgs,
  ): AxiosResponse {
    if (withAuth) {
      if (axiosResponse.status === 401) {
        handleUnauthorized?.();
      }
    }

    return axiosResponse;
  }

  public async defaultHandleUnauthorized(): Promise<void> {
    authenticationService.clearUserTokens();
    window.location.href = `/login?${makeNextParamForCurrent()}`;
    throw new Error("Unauthorized");
  }

  protected getUserTokens(): UserTokens | null {
    return authenticationService.getUserTokens();
  }
}

export const httpClient = new HttpClient();
