import { HttpClient } from "@ll-web/core/api/HttpClient";
import { creatorAuthenticationService } from "@ll-web/creator/auth/async/CreatorAuthenticationService";
import type { UserTokens } from "@ll-web/creator/auth/types";
import { makeNextParamForCurrent } from "@ll-web/utils/helpers/navigation";

class CreatorHttpClient extends HttpClient {
  public async defaultHandleUnauthorized(): Promise<void> {
    creatorAuthenticationService.clearUserTokens();
    window.location.href = `/creator/login?${makeNextParamForCurrent()}`;
    throw new Error("Unauthorized");
  }

  protected getUserTokens(): UserTokens | null {
    return creatorAuthenticationService.getUserTokens();
  }
}

export const creatorHttpClient = new CreatorHttpClient();
