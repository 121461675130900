import { useCallback } from "react";

import { FeatureFlagName } from "@ll-web/config/featureFlags/featureFlags";
import { useAppFlags } from "@ll-web/core/featureFlags/useAppFlags";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { userGroupSwitch } from "@ll-web/features/auth/utils/userGroupSwitch";
import { makeWizardPath } from "@ll-web/features/projectWizard/utils/navigation";

import {
  DashboardUserGroupMap,
  Pages,
  ProjectSpaceUserGroupMap,
} from "./pages";

export const useHomepagePagePath = () => {
  const { activeUser } = useActiveUser();
  const projectsPagePath = userGroupSwitch({
    user: activeUser,
    map: DashboardUserGroupMap,
  });

  return projectsPagePath;
};

export const useProjectHomepagePath = () => {
  const { activeUser } = useActiveUser();

  const projectSpacePagePath = useCallback(
    (id: string) => {
      const path = userGroupSwitch({
        user: activeUser,
        map: ProjectSpaceUserGroupMap,
      });

      return path.replace(":id", id);
    },
    [activeUser],
  );

  return projectSpacePagePath;
};

export const useProjectDetailsPath = () => {
  const flags = useAppFlags();
  const { activeUser } = useActiveUser();
  const isInternalUser = isUserInternal(activeUser);

  const projectDetailsPath = useCallback(
    (id: string) => {
      const path = (() => {
        if (!flags[FeatureFlagName.ProjectSpace]) {
          return makeWizardPath(id);
        }

        if (isInternalUser) {
          return Pages.InternalProjectSpace;
        }

        return Pages.ExternalProjectSpace;
      })();

      return path.replace(":id", id);
    },
    [flags, isInternalUser],
  );

  return projectDetailsPath;
};
